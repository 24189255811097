import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./App";
import { Providers } from "./Providers";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://52ab7e2e6ad53d86397d050792955383@o4507426664349696.ingest.us.sentry.io/4507426668216320",
  enabled: import.meta.env.PROD,
  integrations: [Sentry.browserTracingIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/api\.skyvern\.com\/api\/v1/,
    /^https:\/\/api-staging\.skyvern\.com\/api\/v1/,
  ],
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Providers>
      <App />
    </Providers>
  </React.StrictMode>,
);
