import { getClient } from "@/api/AxiosClient";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { useCredentialGetter } from "@/hooks/useCredentialGetter";
import { useAuth } from "@clerk/clerk-react";
import { CustomerApiResponse } from "@cloud/api/types";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Link, useLocation, useMatch } from "react-router-dom";

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

function BillingAlert() {
  const [show, setShow] = useState(true);
  const credentialGetter = useCredentialGetter();
  const location = useLocation();
  const { userId, isLoaded: isAuthLoaded } = useAuth();
  const { data: customer, isPending: customerIsPending } =
    useQuery<CustomerApiResponse>({
      queryKey: ["customer"],
      queryFn: async () => {
        const client = await getClient(credentialGetter);
        return await client.get("/customer").then((response) => response.data);
      },
    });

  const isWorkflowPage = useMatch("/workflows/:workflowPermanentId/edit");

  if (
    !show ||
    customerIsPending ||
    !customer ||
    !isAuthLoaded ||
    !userId ||
    location.pathname.includes("billing") ||
    isWorkflowPage
  ) {
    return null;
  }

  const creditBalance = customer.credit_balance;

  if (creditBalance >= 100) {
    // in cents
    return null;
  }

  const warningMessage = `You currently have ${currencyFormatter.format(creditBalance / 100)} in credits. This may not be sufficient to run tasks inside Skyvern.`;

  const title = "Low on Credits";
  const message = warningMessage;

  return (
    <div className="mx-auto mb-4 max-w-6xl px-8">
      <Alert variant="warning">
        <ExclamationTriangleIcon className="h-4 w-4" />
        <div className="flex items-center">
          <div>
            <AlertTitle>{title}</AlertTitle>
            <AlertDescription>{message}</AlertDescription>
          </div>
          <div className="ml-auto flex gap-2">
            <Button
              className="ml-auto"
              size="sm"
              variant="secondary"
              onClick={() => {
                setShow(false);
              }}
            >
              Dismiss
            </Button>
            <Button
              asChild
              size="sm"
              onClick={() => {
                setShow(false);
              }}
            >
              <Link to="/billing">Go To Billing</Link>
            </Button>
          </div>
        </div>
      </Alert>
    </div>
  );
}

export { BillingAlert };
