import { Logo } from "@/components/Logo";
import { LogoMinimized } from "@/components/LogoMinimized";
import { useSidebarStore } from "@/store/SidebarStore";
import { Link } from "react-router-dom";
import { SideNav } from "./SideNav";
import { Separator } from "@/components/ui/separator";
import {
  ExternalLinkIcon,
  Link2Icon,
  PinLeftIcon,
  PinRightIcon,
} from "@radix-ui/react-icons";
import { cn } from "@/util/utils";
import { UserButton } from "@clerk/clerk-react";
import { Button } from "@/components/ui/button";

type Props = {
  useCollapsedState?: boolean;
};

function SidebarContent({ useCollapsedState }: Props) {
  const { collapsed: collapsedState, setCollapsed } = useSidebarStore();
  const collapsed = useCollapsedState ? collapsedState : false;

  return (
    <div className="flex h-full flex-col">
      <Link to={window.location.origin}>
        <div className="flex h-24 items-center">
          {collapsed ? <LogoMinimized /> : <Logo />}
        </div>
      </Link>
      <SideNav collapsed={collapsed} />
      <Separator className="my-2" />
      <a
        href="https://docs.skyvern.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="mt-2 flex h-[3.25rem] items-center gap-4 rounded-2xl px-5 py-3 hover:bg-muted">
          <Link2Icon className="h-6 w-6" />
          {!collapsed && <span>API Docs</span>}
        </div>
      </a>
      <a
        href="https://meetings.hubspot.com/skyvern/demo"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="mt-2 flex h-[3.25rem] items-center gap-4 rounded-2xl bg-slate-200 px-5 py-3 font-bold text-slate-950 hover:bg-slate-100">
          <ExternalLinkIcon className="h-6 w-6" />
          {!collapsed && <span>Book a Demo</span>}
        </div>
      </a>
      <div
        className={cn("mt-auto flex min-h-16 items-center", {
          "justify-center": collapsed,
          "justify-between": !collapsed,
        })}
      >
        {!collapsed && (
          <UserButton
            showName
            appearance={{
              elements: {
                userButtonOuterIdentifier: "order-1",
              },
            }}
          />
        )}
        <Button
          size="icon"
          variant="ghost"
          onClick={() => {
            setCollapsed(!collapsed);
          }}
        >
          {collapsed ? (
            <PinRightIcon className="h-6 w-6" />
          ) : (
            <PinLeftIcon className="hidden h-6 w-6 lg:block" />
          )}
        </Button>
      </div>
    </div>
  );
}

export { SidebarContent };
