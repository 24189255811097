import { getClient } from "@/api/AxiosClient";
import { StepApiResponse } from "@/api/types";
import { Skeleton } from "@/components/ui/skeleton";
import { useCostCalculator } from "@/hooks/useCostCalculator";
import { useCredentialGetter } from "@/hooks/useCredentialGetter";
import { useQuery } from "@tanstack/react-query";

type Props = {
  id: string;
};

const formatter = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

function TaskCost({ id }: Props) {
  const credentialGetter = useCredentialGetter();
  const costCalculator = useCostCalculator();

  const { data, isPending } = useQuery<Array<StepApiResponse>>({
    queryKey: ["task", id, "steps"],
    queryFn: async () => {
      const client = await getClient(credentialGetter);
      return client.get(`/tasks/${id}/steps`).then((response) => response.data);
    },
  });

  if (isPending) {
    return <Skeleton className="h-6 w-16" />;
  }

  if (!data || !costCalculator) {
    return null;
  }

  const cost = costCalculator(data ?? []);
  return <span>{formatter.format(cost)}</span>;
}

export { TaskCost };
