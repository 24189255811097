import { getClient } from "@/api/AxiosClient";
import { OrganizationApiResponse } from "@/api/types";
import { Toaster } from "@/components/ui/toaster";
import { useCredentialGetter } from "@/hooks/useCredentialGetter";
import { useSidebarStore } from "@/store/SidebarStore";
import { cn } from "@/util/utils";
import { useAuth, useOrganizationList, useUser } from "@clerk/clerk-react";
import * as Sentry from "@sentry/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePostHog } from "posthog-js/react";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { BillingAlert } from "./BillingAlert";
import { Header } from "./Header";
import { Messenger } from "./Messenger";
import { Sidebar } from "./Sidebar";

function RootLayout() {
  const postHog = usePostHog();
  const collapsed = useSidebarStore((state) => state.collapsed);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [orgIsSet, setOrgIsSet] = useState(false);
  const { userId, orgId: vendorOrgId, isLoaded: isAuthLoaded } = useAuth();
  const user = useUser();
  const credentialGetter = useCredentialGetter();
  const { data: organizations } = useQuery<Array<OrganizationApiResponse>>({
    queryKey: ["organizations"],
    queryFn: async () => {
      const client = await getClient(credentialGetter);
      return await client
        .get("/organizations/")
        .then((response) => response.data.organizations);
    },
  });

  const organization = organizations?.[0];
  const skyvernOrgId = organization?.organization_id;
  const orgName = organization?.organization_name;
  const email = user.user?.primaryEmailAddress?.emailAddress;
  const userName = user.user?.fullName;
  const userCreatedAt = user.user?.createdAt;

  const {
    userMemberships,
    setActive,
    isLoaded: isOrganizationListLoaded,
  } = useOrganizationList({
    userMemberships: {
      infinite: true,
    },
  });

  useEffect(() => {
    if (isAuthLoaded && !userId) {
      navigate(
        `/sign-in?redirect_to=${encodeURIComponent(window.location.href)}`,
      );
      return;
    }
    if (isAuthLoaded && skyvernOrgId) {
      const userProperties: Record<string, string> = {};
      if (email) {
        userProperties.email = email;
      }
      userProperties.organization_id = skyvernOrgId;
      postHog.identify(userId, userProperties);
      Sentry.setUser({ id: userId, email, organization_id: skyvernOrgId });
      if (vendorOrgId) {
        postHog.group("organization", skyvernOrgId, {
          organization_name: orgName,
          organization_id: skyvernOrgId,
        });
      }
    }
  }, [
    isAuthLoaded,
    userId,
    navigate,
    postHog,
    vendorOrgId,
    skyvernOrgId,
    email,
    orgName,
  ]);

  useEffect(() => {
    if (vendorOrgId) {
      if (!orgIsSet) {
        setOrgIsSet(true);
      }
      return;
    }
    if (!isOrganizationListLoaded || !isAuthLoaded) {
      return;
    }
    if (orgIsSet) {
      return;
    }
    if (userMemberships.data.length > 0) {
      setActive({
        organization: userMemberships.data[0]?.organization!.id,
      });
      setOrgIsSet(true);
    }
  }, [
    vendorOrgId,
    isOrganizationListLoaded,
    isAuthLoaded,
    userMemberships,
    orgIsSet,
    setActive,
  ]);

  useEffect(() => {
    queryClient.invalidateQueries();
  }, [vendorOrgId, queryClient]);

  return (
    <>
      <Sidebar />
      <Header />
      <main
        className={cn("lg:pb-4 lg:pl-64", {
          "lg:pl-28": collapsed,
        })}
      >
        <BillingAlert />
        <Outlet />
      </main>
      <Toaster />
      {isAuthLoaded && userId && email && userName && userCreatedAt && (
        <Messenger
          userId={userId}
          userName={userName}
          userEmail={email}
          userCreatedAt={user.user?.createdAt?.getTime() as number}
        />
      )}
    </>
  );
}

export { RootLayout };
