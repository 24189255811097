import { SettingsPageLayout } from "@/routes/settings/SettingsPageLayout";
import { CreateNewTaskFormPage } from "@/routes/tasks/create/CreateNewTaskFormPage";
import { CreateNewTaskLayout } from "@/routes/tasks/create/CreateNewTaskLayout";
import { RetryTask } from "@/routes/tasks/create/retry/RetryTask";
import { TaskTemplates } from "@/routes/tasks/create/TaskTemplates";
import { StepArtifactsLayout } from "@/routes/tasks/detail/StepArtifactsLayout";
import { TaskActions } from "@/routes/tasks/detail/TaskActions";
import { TaskDetails } from "@/routes/tasks/detail/TaskDetails";
import { TaskParameters } from "@/routes/tasks/detail/TaskParameters";
import { TaskRecording } from "@/routes/tasks/detail/TaskRecording";
import { TaskList } from "@/routes/tasks/list/TaskList";
import { TasksPageLayout } from "@/routes/tasks/TasksPageLayout";
import { WorkflowEditor } from "@/routes/workflows/editor/WorkflowEditor";
import { WorkflowPage } from "@/routes/workflows/WorkflowPage";
import { WorkflowRun } from "@/routes/workflows/WorkflowRun";
import { WorkflowRunParameters } from "@/routes/workflows/WorkflowRunParameters";
import { Workflows } from "@/routes/workflows/Workflows";
import { WorkflowsPageLayout } from "@/routes/workflows/WorkflowsPageLayout";
import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";
import { Billing } from "./routes/billing/Billing";
import { BillingPageLayout } from "./routes/billing/BillingPageLayout";
import { Root } from "./routes/root/Root";
import { RootLayout } from "./routes/root/RootLayout";
import { CloudSettings } from "./routes/settings/CloudSettings";
import { SignInPage } from "./routes/sign-in/SignInPage";
import { SignUpPage } from "./routes/sign-up/SignUpPage";

const router = createBrowserRouter([
  {
    element: <Root />,
    children: [
      {
        path: "/sign-in/*",
        element: <SignInPage />,
      },
      {
        path: "/sign-up/*",
        element: <SignUpPage />,
      },
      {
        path: "/",
        element: <RootLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="/create" />,
          },
          {
            path: "tasks",
            element: <TasksPageLayout />,
            children: [
              {
                index: true,
                element: <TaskList />,
              },
              {
                path: ":taskId",
                element: <TaskDetails />,
                children: [
                  {
                    index: true,
                    element: <Navigate to="actions" />,
                  },
                  {
                    path: "actions",
                    element: <TaskActions />,
                  },
                  {
                    path: "recording",
                    element: <TaskRecording />,
                  },
                  {
                    path: "parameters",
                    element: <TaskParameters />,
                  },
                  {
                    path: "diagnostics",
                    element: <StepArtifactsLayout />,
                  },
                ],
              },
            ],
          },
          {
            path: "create",
            element: <CreateNewTaskLayout />,
            children: [
              {
                index: true,
                element: <TaskTemplates />,
              },
              {
                path: ":template",
                element: <CreateNewTaskFormPage />,
              },
              {
                path: "retry/:taskId",
                element: <RetryTask />,
              },
            ],
          },
          {
            path: "workflows",
            element: <WorkflowsPageLayout />,
            children: [
              {
                index: true,
                element: <Workflows />,
              },
              {
                path: ":workflowPermanentId",
                element: <Outlet />,
                children: [
                  {
                    index: true,
                    element: <Navigate to="runs" />,
                  },
                  {
                    path: "edit",
                    element: <WorkflowEditor />,
                  },
                  {
                    path: "run",
                    element: <WorkflowRunParameters />,
                  },
                  {
                    path: "runs",
                    element: <WorkflowPage />,
                  },
                  {
                    path: ":workflowRunId",
                    element: <WorkflowRun />,
                  },
                ],
              },
            ],
          },
          {
            path: "settings",
            element: <SettingsPageLayout />,
            children: [
              {
                index: true,
                element: <CloudSettings />,
              },
            ],
          },
          {
            path: "billing",
            element: <BillingPageLayout />,
            children: [
              {
                index: true,
                element: <Billing />,
              },
            ],
          },
        ],
      },
    ],
  },
]);

export { router };
