import { getClient } from "@/api/AxiosClient";
import { OrganizationApiResponse } from "@/api/types";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Skeleton } from "@/components/ui/skeleton";
import { toast } from "@/components/ui/use-toast";
import { useCredentialGetter } from "@/hooks/useCredentialGetter";
import { MAX_STEPS_DEFAULT } from "@/routes/tasks/constants";
import { MAX_STEPS_MAX_VALUE } from "@cloud/constants";
import { zodResolver } from "@hookform/resolvers/zod";
import { ReloadIcon } from "@radix-ui/react-icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm, useFormState } from "react-hook-form";
import { z } from "zod";

const organizationUpdateFormSchema = z.object({
  maxStepsPerRun: z
    .number()
    .min(1, {
      message: `Max steps per run must be between 1 and ${MAX_STEPS_MAX_VALUE}`,
    })
    .max(MAX_STEPS_MAX_VALUE, {
      message: `Max steps per run must be between 1 and ${MAX_STEPS_MAX_VALUE}`,
    })
    .nullable(),
});

export type OrganizationUpdateFormValues = z.infer<
  typeof organizationUpdateFormSchema
>;

type Props = {
  initialValues: OrganizationUpdateFormValues;
  organizationIsPending: boolean;
  organization?: OrganizationApiResponse;
};

function OrganizationUpdateForm({
  initialValues,
  organization,
  organizationIsPending,
}: Props) {
  const credentialGetter = useCredentialGetter();
  const queryClient = useQueryClient();
  const form = useForm<OrganizationUpdateFormValues>({
    resolver: zodResolver(organizationUpdateFormSchema),
    defaultValues: initialValues,
    values: {
      maxStepsPerRun: organization?.max_steps_per_run ?? null,
    },
  });
  const { isDirty } = useFormState({ control: form.control });

  const organizationUpdateMutation = useMutation({
    mutationFn: async (values: OrganizationUpdateFormValues) => {
      if (!organization) {
        return;
      }
      const client = await getClient(credentialGetter);
      const body = {
        max_steps_per_run: values.maxStepsPerRun,
        organization_name: organization.organization_name,
        webhook_callback_url: organization.webhook_callback_url,
        max_retries_per_step: organization.max_retries_per_step,
      };
      return await client
        .put(`/organizations`, body)
        .then((response) => response.data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["organizations"],
      });
      toast({
        variant: "success",
        title: "Organization updated",
        description: "Your organization settings have been updated",
      });
    },
    onError: (error) => {
      toast({
        variant: "destructive",
        title: "Error updating organization",
        description: error.message,
      });
    },
  });

  function onSubmit(values: OrganizationUpdateFormValues) {
    organizationUpdateMutation.mutate(values);
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="maxStepsPerRun"
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>Max Steps Per Run</FormLabel>
                <FormDescription>
                  Maximum number of steps Skyvern will take before terminating a
                  task
                </FormDescription>
                <FormControl>
                  {organizationIsPending ? (
                    <Skeleton className="h-8" />
                  ) : (
                    <Input
                      {...field}
                      type="number"
                      min={1}
                      max={MAX_STEPS_MAX_VALUE}
                      value={field.value ?? MAX_STEPS_DEFAULT}
                      onChange={(event) => {
                        field.onChange(parseInt(event.target.value));
                      }}
                    />
                  )}
                </FormControl>
              </FormItem>
            );
          }}
        />
        <Button
          type="submit"
          disabled={!isDirty || organizationUpdateMutation.isPending}
        >
          {organizationUpdateMutation.isPending && (
            <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
          )}
          Save
        </Button>
      </form>
    </Form>
  );
}

export { OrganizationUpdateForm };
