import VisaIcon from "@cloud/assets/visa.svg";
import MastercardIcon from "@cloud/assets/mastercard.svg";
import { Button } from "@/components/ui/button";
import { useMutation } from "@tanstack/react-query";
import { getClient } from "@/api/AxiosClient";
import { toast } from "@/components/ui/use-toast";
import { ReloadIcon } from "@radix-ui/react-icons";
import { useCredentialGetter } from "@/hooks/useCredentialGetter";

function AddPaymentMethod() {
  const credentialGetter = useCredentialGetter();

  const checkoutSetupMutation = useMutation({
    mutationFn: async () => {
      const client = await getClient(credentialGetter);
      return await client.post("/checkout/setup");
    },
    onSuccess: (response) => {
      window.location.href = response.data.url;
    },
    onError: () => {
      toast({
        variant: "destructive",
        title: "Error creating payment session",
        description:
          "There was an error creating the payment session. Please try again later.",
      });
    },
  });

  return (
    <div className="w-64 space-y-2 rounded border border-dashed border-slate-600 bg-slate-900 p-4 shadow-md hover:border-slate-500">
      <div className="flex h-6 justify-center gap-2">
        <img src={MastercardIcon}></img>
        <img src={VisaIcon}></img>
      </div>
      <div className="text-center">
        <Button
          onClick={() => {
            checkoutSetupMutation.mutate();
          }}
          disabled={checkoutSetupMutation.isPending}
          variant="ghost"
          className="border"
          size="sm"
        >
          {checkoutSetupMutation.isPending && (
            <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
          )}
          Add Payment Method
        </Button>
      </div>
    </div>
  );
}

export { AddPaymentMethod };
