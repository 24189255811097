import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { useAuth } from "@clerk/clerk-react";
import { CredentialGetterContext } from "@/store/CredentialGetterContext";
import { StepApiResponse } from "@/api/types";
import { CostCalculatorContext } from "@/store/CostCalculatorContext";

function App() {
  const { getToken } = useAuth();

  const credentialGetter = async () => {
    return await getToken({
      template: "skyvern-auth-template",
    });
  };

  const costCalculator = (steps: Array<StepApiResponse>) => {
    return (
      steps
        .filter((step) => step.status !== "running")
        .filter((step) => step.retry_index === 0).length * 0.1
    );
  };

  return (
    <CredentialGetterContext.Provider value={credentialGetter}>
      <CostCalculatorContext.Provider value={costCalculator}>
        <RouterProvider router={router} />
      </CostCalculatorContext.Provider>
    </CredentialGetterContext.Provider>
  );
}

export { App };
