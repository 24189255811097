import { LogoMinimized } from "@/components/LogoMinimized";
import { useThemeAsDarkOrLight } from "@/components/useThemeAsDarkOrLight";
import { ClerkLoaded, ClerkLoading, ClerkProvider } from "@clerk/clerk-react";
import { dark } from "@clerk/themes";

// Import your publishable key
const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key"); // don't even build without this
}

type Props = {
  children: React.ReactNode;
};

function ThemableClerkProvider({ children }: Props) {
  const theme = useThemeAsDarkOrLight();

  return (
    <ClerkProvider
      publishableKey={PUBLISHABLE_KEY}
      appearance={{
        baseTheme: theme === "dark" ? dark : undefined,
        variables: {
          colorBackground: "#020617",
          colorTextOnPrimaryBackground: "#020617",
        },
      }}
    >
      <ClerkLoading>
        <div className="flex h-screen w-screen items-center justify-center">
          <LogoMinimized />
        </div>
      </ClerkLoading>
      <ClerkLoaded>{children}</ClerkLoaded>
    </ClerkProvider>
  );
}

export { ThemableClerkProvider };
