import Intercom from "@intercom/messenger-js-sdk";

type Props = {
  userId: string;
  userName: string;
  userEmail: string;
  userCreatedAt: number;
};

function Messenger({ userId, userName, userEmail, userCreatedAt }: Props) {
  Intercom({
    app_id: "twndna5t",
    user_id: userId,
    name: userName,
    email: userEmail,
    created_at: userCreatedAt,
  });

  return <></>;
}

export { Messenger };
