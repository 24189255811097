import { queryClient } from "@/api/QueryClient";
import { ThemeProvider } from "@/components/ThemeProvider";
import { QueryClientProvider } from "@tanstack/react-query";
import { ThemableClerkProvider } from "./ThemableClerkProvider";

import { PostHogProvider } from "posthog-js/react";

const postHogOptions = {
  api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
};

type Props = {
  children: React.ReactNode;
};

function Providers({ children }: Props) {
  return (
    <PostHogProvider
      apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY}
      options={postHogOptions}
    >
      <QueryClientProvider client={queryClient}>
        <ThemeProvider defaultTheme="dark">
          <ThemableClerkProvider>{children}</ThemableClerkProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </PostHogProvider>
  );
}

export { Providers };
