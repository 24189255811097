import { getClient } from "@/api/AxiosClient";
import { ApiKeyApiResponse, OrganizationApiResponse } from "@/api/types";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { HiddenCopyableInput } from "@/components/ui/hidden-copyable-input";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Skeleton } from "@/components/ui/skeleton";
import { useCredentialGetter } from "@/hooks/useCredentialGetter";
import { useQuery } from "@tanstack/react-query";
import { OrganizationUpdateForm } from "./OrganizationUpdateForm";

function CloudSettings() {
  const credentialGetter = useCredentialGetter();

  const { data: organizations, isPending } = useQuery<
    Array<OrganizationApiResponse>
  >({
    queryKey: ["organizations"],
    queryFn: async () => {
      const client = await getClient(credentialGetter);
      return await client
        .get("/organizations")
        .then((response) => response.data.organizations);
    },
  });

  const organization = organizations?.[0];
  const organizationId = organization?.organization_id;
  const { data: apiKeys, isPending: isPendingApiKeys } = useQuery<
    Array<ApiKeyApiResponse>
  >({
    queryKey: ["apiKeys", organizationId],
    queryFn: async () => {
      const client = await getClient(credentialGetter);
      return await client
        .get(`/organizations/${organizationId}/apikeys`)
        .then((response) => response.data.api_keys);
    },
    enabled: !!organizationId, // don't run this until organization id exists
  });

  return (
    <div className="flex flex-col gap-8">
      <Card>
        <CardHeader className="border-b-2">
          <CardTitle className="text-lg">Organization</CardTitle>
          <CardDescription>Your organization information</CardDescription>
        </CardHeader>
        <CardContent className="p-8">
          <div className="flex flex-col gap-4">
            <div className="flex items-center gap-4">
              <Label className="w-36 whitespace-nowrap">Organization ID</Label>
              {isPending ? (
                <Skeleton className="h-8 w-full" />
              ) : (
                <Input value={organization?.organization_id} readOnly />
              )}
            </div>
            <div className="flex items-center gap-4">
              <Label className="w-36 whitespace-nowrap">
                Organization Name
              </Label>
              {isPending ? (
                <Skeleton className="h-8 w-full" />
              ) : (
                <Input value={organization?.organization_name} readOnly />
              )}
            </div>
          </div>
        </CardContent>
      </Card>
      <Card>
        <CardHeader className="border-b-2">
          <CardTitle className="text-lg">API Keys</CardTitle>
          <CardDescription>
            API keys belonging to your organization
          </CardDescription>
        </CardHeader>
        <CardContent className="p-8">
          {isPendingApiKeys || isPending ? (
            <Skeleton className="h-8 w-full" />
          ) : (
            <div className="flex flex-col gap-4">
              {apiKeys?.map((apiKey) => (
                <HiddenCopyableInput key={apiKey.id} value={apiKey.token} />
              ))}
            </div>
          )}
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle className="text-lg">Organization Settings</CardTitle>
          <CardDescription>Settings for your organization</CardDescription>
        </CardHeader>
        <CardContent className="px-8">
          {!isPending && organization && (
            <OrganizationUpdateForm
              initialValues={{
                maxStepsPerRun: organization.max_steps_per_run,
              }}
              organizationIsPending={isPending}
              organization={organization}
            />
          )}
        </CardContent>
      </Card>
    </div>
  );
}

export { CloudSettings };
