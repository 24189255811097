import VisaIcon from "@cloud/assets/visa.svg";
import MastercardIcon from "@cloud/assets/mastercard.svg";
import AmexIcon from "@cloud/assets/amex.svg";
import BillingIcon from "@cloud/assets/billing.svg";

type PaymentMethodProps = {
  last4: string;
  expMonth: number;
  expYear: number;
  brand: "visa" | "mastercard" | "amex";
};

const brandToIcon = {
  visa: VisaIcon,
  mastercard: MastercardIcon,
  amex: AmexIcon,
};

const PaymentMethodCard = ({
  last4,
  expMonth,
  expYear,
  brand,
}: PaymentMethodProps) => {
  return (
    <div className="flex w-64 items-center justify-between rounded-lg border bg-slate-900 p-4 shadow-md">
      <div className="flex items-center">
        <img
          src={brandToIcon[brand] ?? BillingIcon}
          alt={brand}
          className="mr-4 w-12"
        />
        <div className="space-y-2">
          <p className="font-semibold">Ending in {last4}</p>
          <p className="text-sm text-muted-foreground">
            Expires {expMonth}/{expYear}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethodCard;
