import { getClient } from "@/api/AxiosClient";
import { TaskApiResponse } from "@/api/types";
import { StatusBadge } from "@/components/StatusBadge";
import { Skeleton } from "@/components/ui/skeleton";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useCredentialGetter } from "@/hooks/useCredentialGetter";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TaskCost } from "./TaskCost";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import { cn } from "@/util/utils";

const PAGE_SIZE = 15;

function BillingHistory() {
  const credentialGetter = useCredentialGetter();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
  const navigate = useNavigate();

  const { data: tasks, isPending } = useQuery<Array<TaskApiResponse>>({
    queryKey: ["tasks", "all", page],
    queryFn: async () => {
      const client = await getClient(credentialGetter);
      const params = new URLSearchParams();
      params.append("page", String(page));
      params.append("page_size", String(PAGE_SIZE));
      return client
        .get("/tasks", {
          params,
        })
        .then((response) => response.data);
    },
  });

  const skeleton = Array.from({ length: PAGE_SIZE }).map((_, index) => (
    <TableRow key={index}>
      <TableCell className="w-1/4">
        <Skeleton className="h-6 w-full" />
      </TableCell>
      <TableCell className="w-1/4">
        <Skeleton className="h-6 w-full" />
      </TableCell>
      <TableCell className="w-1/4">
        <Skeleton className="h-6 w-full" />
      </TableCell>
      <TableCell className="w-1/4">
        <Skeleton className="h-6 w-full" />
      </TableCell>
    </TableRow>
  ));

  return (
    <div className="rounded-md border">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-1/4">ID</TableHead>
            <TableHead className="w-1/4">URL</TableHead>
            <TableHead className="w-1/4">Status</TableHead>
            <TableHead className="w-1/4">Cost</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {isPending && skeleton}
          {!isPending && tasks?.length === 0 && (
            <TableRow>
              <TableCell colSpan={4}>No tasks found</TableCell>
            </TableRow>
          )}
          {tasks?.map((task) => (
            <TableRow
              key={task.task_id}
              className="cursor-pointer"
              onClick={(event) => {
                if (event.ctrlKey || event.metaKey) {
                  window.open(
                    window.location.origin + `/tasks/${task.task_id}/actions`,
                    "_blank",
                    "noopener,noreferrer",
                  );
                } else {
                  navigate(`/tasks/${task.task_id}/actions`);
                }
              }}
            >
              <TableCell>{task.task_id}</TableCell>
              <TableCell className="max-w-64 truncate">
                {task.request.url}
              </TableCell>
              <TableCell>
                <StatusBadge status={task.status} />
              </TableCell>
              <TableCell>
                <TaskCost id={task.task_id} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination className="pt-2">
        <PaginationContent>
          <PaginationItem>
            <PaginationPrevious
              className={cn({ "cursor-not-allowed": page === 1 })}
              onClick={() => {
                if (page === 1) {
                  return;
                }
                const params = new URLSearchParams();
                params.set("page", String(Math.max(1, page - 1)));
                setSearchParams(params, { replace: true });
              }}
            />
          </PaginationItem>
          <PaginationItem>
            <PaginationLink>{page}</PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationNext
              onClick={() => {
                const params = new URLSearchParams();
                params.set("page", String(page + 1));
                setSearchParams(params, { replace: true });
              }}
            />
          </PaginationItem>
        </PaginationContent>
      </Pagination>
    </div>
  );
}

export { BillingHistory };
