import { SignIn } from "@clerk/clerk-react";
import { useSearchParams } from "react-router-dom";

function SignInPage() {
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get("redirect_to");

  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <SignIn fallbackRedirectUrl={redirectTo} />
    </div>
  );
}

export { SignInPage };
